<section [@slideInOut] class="slide-contact">
  <img [src]="ImagePath" (click)="ImageClick()" alt="Salamon Szilard" />
  <div class="name-card">
    <h5>Salamon Szilárd</h5>
    <span>Sitebuilder / Frontend Developer</span>
    <div class="icons">
      <a href="https://www.facebook.com/salamon.szilard/" title="Facebook link" target="_blank"><i class="icon icon-facebook"></i></a>
      <a href="https://www.linkedin.com/in/salamon-szilard/" target="_blank" title="Linkedin"><i class="icon icon-linkedin"></i></a>
      <a href="https://github.com/sziszi86/" target="_blank"><i class="icon icon-github"></i> </a>
    </div>
  </div>
  <div class="contact-card">
    <div class="data">
      <i class="icon icon-phone"></i>
      <div class="data-wrapper">
        <span class="mini-title">Telefon</span>
        <a href="tel:+36-30-657-3410" title="telefon"><span class="target">+36 30 657 3410</span></a>
      </div>
    </div>
    <hr />
    <div class="data">
      <i class="icon icon-email"></i>
      <div class="data-wrapper">
        <span class="mini-title">Email</span>
        <a href="mailto:salamonszilard@gmail.com" title="email"><span class="target">salamonszilard@gmail.com</span></a>
      </div>
    </div>
    <hr />
    <div class="data">
      <i class="icon icon-location"></i>
      <div class="data-wrapper">
        <span class="mini-title">Lakhely</span>
        <span class="target">Sopron</span>
      </div>
    </div>
    <hr />
    <div class="data">
      <i class="icon icon-phone"></i>
      <div class="data-wrapper">
        <span class="mini-title">Születésnap</span>
        <span class="target">1986.01.30.</span>
      </div>
    </div>
  </div>
  <button title="Önéletrajz letöltése" onclick="window.open('../../../assets/salamon_szilard_cv.pdf')">
    <i class="icon icon-download"></i> Önéletrajz letöltése
  </button>
</section>
